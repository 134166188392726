<template>
  <el-dialog
    :title="$t('common.edit')+$t('workOrder.workOrder')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="500px"
    top="5vh"
    @close="onDialogClose">
    <el-form
      ref="formValidate"
      class="form"
      label-width="150px"
      :model="maintWorkOrder"
      :rules="ruleValidate">
      <el-form-item :label="$t('maintWorkOrder.date')" prop="maintDate" width="140px">
        <el-date-picker
          v-model="maintWorkOrder.maintDate"
          type="date"
          :placeholder="$t('yearCheck.date')"
          :clearable="false"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  const moduleName = "maintWorkOrder";
  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        saveDisabled: false,
        maintWorkOrder: {
          maintDate: "",
          id: "",
        },
        ruleValidate: {
          maintDate: [
            {required: true, message: this.$t("maintWorkOrder.tip.date"), trigger: "blur"},
          ],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() <= Date.now() - 3600 * 1000 * 24;
          },
        },
      };
    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        this.maintWorkOrder = row;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.maintWorkOrder).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
