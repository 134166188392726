<template>
  <el-dialog
    :title="$l('Add Maintenance Plan','新增维保计划')"
    class="small-padding"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1100px"
    top="2vh"
    @close="onDialogClose">
    <el-alert
      class="tip"
      :title="$l('maintWorkOrder.title','每台电梯只需新增一次维保计划，后面的维保计划会在上一次维保完成或者超期之后自动生成，无需再次添加。若电梯已有未完工的保养工单/未配置维保公司/未配置维保工，创建时会忽略此电梯。')"
      :closable="false"
      type="warning">
    </el-alert>
    <el-form
      ref="formValidate"
      class="form"
      label-width="110px"
      :model="maintWorkOrder"
      :rules="ruleValidate">
      <el-form-item :label="$t('maintWorkOrder.date')" prop="maintDate">
        <el-date-picker
          v-model="maintWorkOrder.maintDate"
          type="date"
          :placeholder="$t('yearCheck.date')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"></el-date-picker>
      </el-form-item>
      <div>
        <el-button

          type="primary"
          icon="el-icon-plus"
          @click="$refs.selectElevator.open()"
        >
          {{$t("elevator.addElevator")}}
        </el-button>
        <elevator-select
          ref="selectElevator"
          multi-selection
          @select="onSelectElevator"
          @multi-select="onMultiSelectElevator"></elevator-select>
      </div>
      <el-table
        :data="elevators"
        border
        stripe

        style="width: 100%;margin-top: 10px">
        <el-table-column prop="no" :label="$t('elevator.no')"></el-table-column>
        <el-table-column prop="name" :label="$t('elevator.name')"></el-table-column>
        <el-table-column prop="productTypeName" :label="$t('elevator.elevatorType')"></el-table-column>
        <el-table-column prop="maintComName" :label="$t('maintWorkOrder.company')"></el-table-column>
        <el-table-column prop="maintainerName1" :label="$t('elevator.maintWorker') + '1'"></el-table-column>
        <el-table-column prop="maintainerName2" :label="$t('elevator.maintWorker') + '2'"></el-table-column>
        <el-table-column :label="$t('elevator.maintPeriod')" width="150px"> 15 </el-table-column>
        <el-table-column :label="$t('common.operation')" width="90px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
              {{$t("common.remove")}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        saveDisabled: false,
        maintDate: "",
        maintWorkOrder: {
          maintDate: "",
          id: "",
        },
        elevators: [],
        ruleValidate: {
          maintDate: [
            {required: true, message: this.$t("maintWorkOrder.tip.date"), trigger: "blur"},
          ],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 3600 * 1000 * 24;
          },
        },
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      onSelectElevator(row) {
        let flag = false;
        for (let item of this.elevators) {
          if (item.no === row.no) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          this.elevators.push(row);
        }
      },
      onMultiSelectElevator(rows) {
        for (let item of rows) {
          this.onSelectElevator(item);
        }
      },
      handleSubmit() {
        let maintWorkOrders = [];
        for (let i = 0; i < this.elevators.length; i++) {
          let temp = {};
          temp.elevatorId = this.elevators[i].id;
          temp.maintDate = this.maintWorkOrder.maintDate;
          temp.status = 1;
          temp.maintCom = this.elevators[i].maintComName;
          temp.maintainer = this.elevators[i].maintEmpName;
          temp.maintainerId = this.elevators[i].maintEmpId;
          temp.maintainer1 = this.elevators[i].maintEmpName1;
          temp.maintainerId1 = this.elevators[i].maintEmpId1;
          maintWorkOrders.push(temp);
        }
        this.saveMaintWorkOrder(maintWorkOrders);
      },
      saveMaintWorkOrder(maintWorkOrders) {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save("maintWorkOrder", maintWorkOrders).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess") + " " + res.data + "条");
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      deleteRow(index) {
        this.elevators.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
.tip {
  margin-top: 5px;
  margin-bottom: 15px;
}
</style>
