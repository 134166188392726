<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="maintWorkOrder/web" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$l('maintWorkOrder.status', '状态')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('elevator.elevator', '电梯')">
          <vm-select-input v-model="search.elevatorName" clearable @clear="search.elevatorId = ''"
            @select="$refs.selectElevator.open()"></vm-select-input>
          <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
        </vm-search>
        <vm-search :label="$l('workOrder.workOrderStatus', '计划维保日期')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" style="width: 100%;" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button type="primary" size="mini" @click="handleAdd">
          {{ $l("elevatorNetApply.add", "新增") }}
        </el-button>
        <el-button type="danger" style="margin-left: 5px" @click="handleBatchDelete">
          {{ $l("maintWorkOrder.batchDelete", "批量删除") }}
        </el-button>
        <el-button :loading="exportLoading" type="primary" @click="exportExcel">{{ $l("maintWorkOrder.export", "导出") }}
        </el-button>
      </template>
      <el-table-column prop="no" :label="$t('maintWorkOrder.no')" width="110px">
        <template slot-scope="scope">
          <router-link :to="'/maintWorkOrder/' + scope.row.id" target="_blank">{{ scope.row.no }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="140px"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('elevator.name')"></el-table-column>
      <el-table-column prop="maintCom" :label="$t('maintWorkOrder.company')"></el-table-column>
      <el-table-column prop="maintainer" :label="$t('elevator.maintWorker') + '1'" width="80px"></el-table-column>
      <el-table-column prop="maintainer1" :label="$t('elevator.maintWorker') + '2'" width="80px"></el-table-column>
      <el-table-column prop="maintDate" :label="$t('maintWorkOrder.date')" width="175px"></el-table-column>
      <el-table-column prop="finishTime" :label="$l('maintWorkOrder.actualMaintenanceTime', '实际维保时间')"
        width="175px"></el-table-column>
      <el-table-column prop="status" :label="$t('maintWorkOrder.status')" width="150px">
        <template slot-scope="scope">
          <template v-if="scope.row.status === 1">
            <el-tag>{{ $t("maintWorkOrder.works") }}</el-tag>
            <el-tag v-if="compareTodayDate(scope.row.maintDate)" type="danger">
              {{ $t("maintWorkOrder.ultraTime") }}
            </el-tag>
          </template>
          <template v-else-if="scope.row.status === 2">
            <el-tag>{{ $t("maintWorkOrder.execution") }}</el-tag>
            <el-tag v-if="compareTodayDate(scope.row.maintDate)" type="danger">
              {{ $t("maintWorkOrder.ultraTime") }}
            </el-tag>
          </template>
          <el-tag v-else-if="scope.row.status === 3" type="warning">
            {{ $t("maintWorkOrder.stocks") }}
          </el-tag>
          <el-tag v-else-if="scope.row.status === 4" type="success">
            {{ $t("maintWorkOrder.confirmed") }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="confirmAuth || editAuth || deleteAuth" :label="$t('common.operation')" width="175px"
        align="center">
        <template slot-scope="scope">
          <el-button v-if="confirmAuth" :disabled="scope.row.status !== 3" type="success" @click="handleSure(scope.row)">
            {{ $t("maintWorkOrder.affirm") }}
          </el-button>
          <el-button v-if="editAuth" :disabled="scope.row.status !== 1" @click="handleEdit(scope.row)">
            {{ $t("common.edit") }}
          </el-button>
          <el-button v-if="deleteAuth" type="danger" @click="handleDelete(scope.row)">
            {{ $t("common.delete") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <add-dialog ref="addPage" @save-success="getList(1)"></add-dialog>
    <edit-dialog ref="editPage" @save-success="getList(currentPage)"></edit-dialog>
    <delete-dialog ref="deletePage" @save-success="getList(1)"></delete-dialog>
  </div>
</template>
<script>
import AddDialog from "./MaintWorkOrderAdd.vue";
import EditDialog from "./MaintWorkOrderEdit.vue";
import DeleteDialog from "./MaintWorkOrderDelete.vue";
import VmSelectInput from "../../components/VmSelectInput";
import ElevatorSelect from "../elevator/ElevatorSelect.vue";

export default {
  components: { AddDialog, EditDialog, DeleteDialog, VmSelectInput, ElevatorSelect },
  data() {
    return {
      addAuth: this.$auth(314),
      editAuth: this.$auth(311),
      deleteAuth: this.$auth(313),
      confirmAuth: this.$auth(318),
      loading: false,
      exportLoading: false,
      search: {
        filter: "",
        status: "",
        elevatorId: "",
        elevatorName: "",
        startTime: "",
        endTime: "",
      },
      dateRange: [],
      stateOptions: [
        { value: "1", label: this.$t("maintWorkOrder.works") },
        { value: "2", label: this.$t("maintWorkOrder.execution") },
        { value: "3", label: this.$t("maintWorkOrder.stocks") },
        { value: "4", label: this.$t("maintWorkOrder.confirmed") },
        { value: "5", label: this.$t("maintWorkOrder.hasExtended") }],
      today: "",
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.$refs.vmTable.getList(pageIndex);
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleAdd() {
      this.$refs.addPage.open();
    },
    handleEdit(row) {
      this.$refs.editPage.open(row);
    },
    clearDateRange() {
      this.dateRange = [];
    },
    handleSure(row) {
      this.$confirm(this.$t("maintWorkOrder.tip.confirm") + row.no + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.submitLoading = true;
        this.$axios.put("maintWork/confirm?id=" + row.id).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.getList(this.currentPage);
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      });
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("maintWorkOrder.no") + " " + row.no + "，" + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$api.deleteById("maintWork", row.id).then(res => {
          this.getList(this.currentPage);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
    changePage(pageIndex) {
      this.getList(pageIndex);
    },
    compareTodayDate(oldDateStr) {
      return new Date(oldDateStr).getTime() <= Date.now() - 3600 * 1000 * 24;
    },
    onSelectElevator(row) {
      this.search.elevatorId = row.id;
      this.search.elevatorName = row.name;
    },
    handleBatchDelete() {
      this.$refs.deletePage.open();
    },
    exportExcel() {
      this.exportLoading = true;
      let params = {
        sort: 0,
        no: this.search.no,
        status: this.search.status,
        elevatorId: this.search.elevatorId,
        startTime: this.search.dateRange ? this.search.dateRange[0] : "",
        endTime: this.search.dateRange ? this.search.dateRange[1] : "",
      };
      this.$api
        .exportFile("maintWorkOrder/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "维保工单.xlsx";
          } else {
            fileName = "Maintenance WorkOrder.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
