<template>
  <el-dialog
    :title="$l('maintWorkOrder.batchDeleteMaint','批量删除维保计划')"
    class="small-padding"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1100px"
    top="2vh"
    @close="onDialogClose">
    <el-alert
      class="tip"
      :title="$l('maintWorkOrder.tips','此功能会删除【所选电梯同时所选状态】的所有保养工单，请谨慎使用。删除计划中的工单后不会继续自动生成保养工单，相当于暂停维保')"
      :closable="false"
      type="warning">
    </el-alert>
    <el-form ref="formValidate" class="form" :label-width="$l('maintWorkOrder.labelWidth','110px')">
      <el-form-item :label="$l('maintWorkOrder.maintenancePolicyStatus','维保单状态')">
        <el-radio-group v-model="status">
          <el-radio :label="0">{{$l("maintWorkOrder.allState","所有状态")}}</el-radio>
          <el-radio :label="1">{{$l("maintWorkOrder.onlyPlan","仅计划中")}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div>
        <el-button

          type="primary"
          icon="el-icon-plus"
          @click="$refs.selectElevator.open()"
        >
          {{$t("elevator.addElevator")}}
        </el-button>
        <elevator-select
          ref="selectElevator"
          multi-selection
          @select="onSelectElevator"
          @multi-select="onMultiSelectElevator"></elevator-select>
      </div>
      <el-table
        :data="elevators"
        border
        stripe

        style="width: 100%;margin-top: 10px">
        <el-table-column prop="no" :label="$t('elevator.no')"></el-table-column>
        <el-table-column prop="name" :label="$t('elevator.name')"></el-table-column>
        <el-table-column prop="productTypeName" :label="$t('elevator.elevatorType')"></el-table-column>
        <el-table-column prop="maintComName" :label="$t('maintWorkOrder.company')"></el-table-column>
        <el-table-column prop="maintainerName1" :label="$t('elevator.maintWorker') + '1'"></el-table-column>
        <el-table-column prop="maintainerName2" :label="$t('elevator.maintWorker') + '2'"></el-table-column>
        <el-table-column width="150px" :label="$t('elevator.maintPeriod')"> 15 </el-table-column>
        <el-table-column :label="$t('common.operation')" width="90px" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
              {{$t("common.remove")}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="danger" :loading="submitLoading" @click="handleSubmit">
        {{$t("common.delete")}}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        status: 0,
        elevators: [],
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
      },
      onDialogClose() {
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      onSelectElevator(row) {
        let flag = false;
        for (let item of this.elevators) {
          if (item.no === row.no) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          this.elevators.push(row);
        }
      },
      onMultiSelectElevator(rows) {
        for (let item of rows) {
          this.onSelectElevator(item);
        }
      },
      handleSubmit() {
        if (this.elevators.length === 0) {
          this.$message.error("未选择电梯");
          return;
        }
        this.$confirm("确定删除吗？", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.submit();
        });
      },
      submit() {
        let elevatorIds = this.elevators.map(item => {
          return item.id;
        });
        this.$axios.post("maintWorkOrder/batchDelete?status=" + this.status, elevatorIds).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      },
      deleteRow(index) {
        this.elevators.splice(index, 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
.tip {
  margin-top: -5px;
  margin-bottom: 15px;
}
</style>
